
export default {
	name: "CoreBlockUnknown",
	props: {
		isEditable: {
			type: Boolean,
			default: false,
		},
		settings: {
			type: Object,
			required: true,
		},
	},
};
